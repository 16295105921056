<template>
  <div class="church-list template-1">
    <page-header class="mx-lg">
      <h1>{{ translations.tcLeadershipAndManagement }}</h1>
    </page-header>
    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components"></camp-selector>
    <div class="row">
      <div class="col-7"></div>
      <div class="col-5 pt-3 dlcss d-flex">
        <div class="row">
          <div class="col-6" v-if="!['IOC', 'ICC'].includes(obj.orgType)">
            <strong>{{ translations.tcMemberType }}:</strong>
          </div>
          <div class="col-6">
            <form class="filters d-flex justify-content-start justify-content-sm-end"
              v-if="!['IOC', 'ICC'].includes(obj.orgType)">
              <b-form-select :options="category" :plain="true" v-model="selectedCategory"
                @change="selectedCategoryAction" class="church-select mr-0 mr-sm-2 mb-3 mb-sm-0 regionzone">
              </b-form-select>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div v-if="!['IOC', 'ICC'].includes(obj.orgType)">
        <data-table :fields="dataTableFields" :items="obj.member" :addItems="addItems" :showExportBtn="false"
          :title="labelMember" :i18n="this.translations.components">
        </data-table>
      </div>
      <data-table :fields="dataTableFieldsMgt" :items="obj.mgt" :addItems="addItems" :selectItems="selectedItem"
        :showExportBtn="false" :title="labelMgt" :i18n="this.translations.components" :searchTerm="`managementTerm`">
      </data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SwitchItem from '@/components/SwitchItem.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'management-team',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      response: null,
      search: '',
      labelMember: 'Gideon Cabinet',
      labelMgt: 'Gideon Management Team',
      positionList: [],
      selectedItem: { display: false, action: '#' },
      memberList: [],
      isLoading: true,
      positionLabel: '',
      isLoadingMember: false,
      selectedCategory: 'gideon',
      obj: { member: [], mgt: [], orgType: null },
      dataTableFields: [
        {
          label: 'Name',
          key: 'member_name',
          sortable: true,
        },
        {
          label: 'Office',
          key: 'office',
          sortable: true,
        },
      ],
      dataTableFieldsMgt: [
        {
          label: 'Name',
          key: 'member_name',
          sortable: true,
        },
        {
          label: 'Region',
          key: 'committee_name',
          sortable: true,
        },
        {
          label: 'Office',
          key: 'office',
          sortable: true,
        },
      ],
      addItems: {
        display: false,
        text: 'Add Member',
        action: '',
      },
      category: [
        { text: 'Gideon', selected: true, value: 'gideon' },
        { text: 'Auxiliary', selected: false, value: 'aux' },
      ],
    }
  },
  methods: {
    ...mapActions({
      getManagementTeam: 'officersModule/getManagementTeam',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async individualStoreKeyAndRedirect(ind_key) {
      await this.setSelectedIndividualKey(ind_key)
      this.$router.push({ path: '/programs/mb/member-profile/' })
    },
    async page_load() {
      this.setLoadingStatus(true)
      try {
        let promiseList = [
          this.getManagementTeam(),
          this.getViewTranslations('geographic-areas'),
          this.getComponentTranslations('camp-select', 'data-table'),
          this.getComponentTranslations('common.committee-positions'),
        ]
        if (this.translations && this.translations.common) {
          promiseList = promiseList.slice(0, 1) // Avoid unneeded API calls, remove translation calls after view is loaded
        }
        await Promise.all(promiseList).then((results) => {
          if (results.length > 1) {
            // only parse `results` when translations are called
            this.stripReadableText(results[3])
            const translatedText = {
              ...results[2],
              common: { ...this.translations.common },
            }
            this.$set(this.translations, 'components', translatedText)
            this.loadDataTableFields()
          }

          this.response = results[0]
          if (typeof this.response === 'object') {
            const { tcGideonManagementTeam, tcLeadershipTeam } = this.translations
            this.obj.member = this.GideonLeadershipList(this.selectedCategory === 'gideon' ? this.response.gideon.gideon : this.response.aux.aux)
            this.obj.mgt = this.GideonLeadershipList(this.selectedCategory === 'gideon' ? this.response.gideon.gideon_mgt : this.response.aux.aux_mgt)
            this.obj.orgType = this.selectedCategory === 'gideon' ? this.response.gideon.orgType : this.response.aux.orgType
            this.labelMgt = ['IOC', 'ICC'].includes(this.obj.orgType) ? tcLeadershipTeam : tcGideonManagementTeam
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    GideonLeadershipList(ObjMangement) {
      if (!!ObjMangement) {
        return ObjMangement.map((obj) => {
          let regionText = this.translateRegionText(obj.office)
          const officePositionGuid = obj.vctp_ctp_key || ''
          let officePositionText = this.translations.common['committee-positions'].hasOwnProperty(officePositionGuid)
            ? this.translations.common['committee-positions'][officePositionGuid]
            : obj.office

          // Translate committee name (IE, `State Region East`)
          let committeeVal = obj.committee_name ? obj.committee_name : ''
          committeeVal = committeeVal ? `tc${this.removeSpacesAndSpecialChars(committeeVal)}` : ''
          const committeeName = this.translations.hasOwnProperty(committeeVal)
            ? this.translations[committeeVal]
            : obj.committee_name

          return {
            member_name: obj.name,
            committee_name: committeeName,
            ind_key: obj.ind_key,
            office: officePositionText + regionText,
          }
        })
      }
      return null
    },
    selectedCategoryAction() {
      if (this.selectedCategory === 'gideon') {
        const { tcGideonManagementTeam, tcLeadershipTeam } = this.translations
        this.labelMember = this.translations.tcGideonCabinet
        this.labelMgt = ['IOC', 'ICC'].includes(this.obj.orgType) ? tcLeadershipTeam : tcGideonManagementTeam
        this.obj.member = this.GideonLeadershipList(this.response.gideon.gideon)
        this.obj.mgt = this.GideonLeadershipList(this.response.gideon.gideon_mgt)
      } else {
        this.labelMember = this.translations.tcAuxiliaryCabinet
        this.labelMgt = this.translations.tcAuxiliaryManagementTeam
        this.obj.member = this.GideonLeadershipList(this.response.aux.aux)
        this.obj.mgt = this.GideonLeadershipList(this.response.aux.aux_mgt)
      }
    },
    loadDataTableFields() {
      const { tcAddMember, tcAuxiliary, tcGideon, tcGideonCabinet } = this.translations
      this.addItems.text = tcAddMember
      this.category[0].text = tcGideon
      this.category[1].text = tcAuxiliary
      this.labelMember = tcGideonCabinet

      this.dataTableFields = [
        {
          label: this.translations.tcName,
          key: 'member_name',
          sortable: true,
        },
        {
          label: this.translations.tcOffice,
          key: 'office',
          sortable: true,
        },
      ]
      this.dataTableFieldsMgt = [
        {
          label: this.translations.tcName,
          key: 'member_name',
          sortable: true,
        },
        {
          label: this.translations.tcRegion,
          key: 'committee_name',
          sortable: true,
        },
        {
          label: this.translations.tcOffice,
          key: 'office',
          sortable: true,
        },
      ]
    },
    translateRegionText(givenRegion) {
      // TODO - this will likely need more flexibility to handle other Regions/Special Text
      if (!givenRegion) return ''

      if (givenRegion.includes('(State Area')) {
        const regionNum = givenRegion.split('(State Area')[1].trim() // contains a `)` at the end of the string
        const stateArea = this.translations.tcStateArea
        return ` (${stateArea} ${regionNum}` // `(State Area 003)`
      }
      return ''
    },
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  components: {
    campSelector: CampSelect,
    DataTable: DataTable,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    'switch-item': SwitchItem,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.dlcss {
  width: 20%;

  .col-6 {
    margin: auto;
  }
}
</style>
